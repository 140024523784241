/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "button": {},
  "textField": {},
  "checkbox": {},
  "klarnaPayment": {},
  "form_colors_default": "",
  "form_size_default": "",
  "form_variant_default": "",
  "form_errors_colors_default": "",
  "form_errors_size_default": "",
  "form_errors_variant_default": "",
  "form_errors_message_colors_default": "",
  "form_errors_message_size_default": "",
  "form_errors_message_variant_default": "",
  "closed_colors_default": "",
  "closed_size_default": "",
  "closed_variant_default": "",
  "colors_default": "",
  "size_default": "gap-y-5",
  "variant_default": "flex flex-col"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Checkout");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;